import { Component, IComponentOptions } from '../component';
import { GenericComponentFactory, IComponentFactoryOptions } from '../component-factory';
import * as dom from '../dom';
import * as utility from '../utility';

export interface IPaginationComponentOptions extends IComponentOptions {
	itemArray: any[];
	size: number;
	initPage?: null | number;
}

export class PaginationComponent extends Component {
	private _paginatedArray: any[];
	private _initPageIndex: number;
	private _currentPageIndex: number;
	private _paginationSize: number;
	private _paginationButtons: HTMLElement[];
	private _paginationTextfield: HTMLInputElement;
	private _navigationElement: HTMLElement;
	private _originalItems: any[];

	constructor(element: HTMLElement, options: IPaginationComponentOptions) {
		super(element, options);
		this._navigationElement = element;
		this._paginationSize = options.size;
		this._initPageIndex = options.initPage ? options.initPage - 1 : 0;
		this._currentPageIndex = options.initPage;
		this.items = options.itemArray;
	}

	set items(itemArray: any[]) {
		this._originalItems = utility.toArray(itemArray);
		this._currentPageIndex = 0;

		const tempArray = [];
		while (itemArray && itemArray.length > 0) {
			tempArray.push(itemArray.splice(0, this._paginationSize));
		}

		this._paginatedArray = tempArray;

		this.destroy();
		this.createNavigation();
	}

	get items() {
		return this._paginatedArray;
	}

	set paginationSize(size: number) {
		const items = utility.toArray(this._originalItems);
		this._paginationSize = size;
		this.items = items;
	}

	set currentPageIndex(pageIndex: number) {
		if (pageIndex !== -1 && pageIndex < this._paginatedArray.length) {
			this._currentPageIndex = pageIndex;
		} else {
			this._currentPageIndex = pageIndex === -1 ?  0 : this._paginatedArray.length - 1;
		}

		this._paginationButtons.forEach((paginationButton) => paginationButton.removeAttribute('disabled'));

		if (pageIndex >= (this._paginatedArray.length - 1)) {
			this._paginationButtons[2].setAttribute('disabled', 'true');
			this._paginationButtons[3].setAttribute('disabled', 'true');
		}

		if (pageIndex <= 0) {
			this._paginationButtons[0].setAttribute('disabled', 'true');
			this._paginationButtons[1].setAttribute('disabled', 'true');
		}

		this._paginationTextfield.value = (this._currentPageIndex + 1).toString();

		dom.dispatchEvent(this._navigationElement, 'iris.pagination.change', {
			items: this._paginatedArray[this._currentPageIndex],
			currentPage: this._currentPageIndex + 1,
			component: this
		});
	}

	get currentPageIndex() {
		return this._currentPageIndex;
	}

	public createNavigation(): void {
		this._paginationButtons = [];

		const firstButtonClickHander = () => {
			this.currentPageIndex = 0;
		};

		const backButtonClickHandler = () => {
			this.currentPageIndex -= 1;
		};

		const nextButtonClickHandler = () => {
			this.currentPageIndex += 1;
		};

		function backButtonKeyHandler(e: KeyboardEvent) {
			if (e.key === 'Enter') {
				this.currentPageIndex -= 1;
			}
		}

		function nextButtonKeyHandler(e: KeyboardEvent) {
			if (e.key === 'Enter') {
				this.currentPageIndex += 1;
			}
		}

		const lastButtonClickHander = () => {
			this.currentPageIndex = this._paginatedArray.length - 1;
		};

		const inputClickHandler = (e: Event) => {
			(e.target as HTMLInputElement).select();
		};

		const inputKeyPresshandler = (e: KeyboardEvent) => {
			if (e.key === 'Enter') {
				this.currentPageIndex = parseInt((e.target as HTMLInputElement).value, 10) - 1;
			}
		};

		const firstButton = document.createElement('button');
		firstButton.classList.add('iris-pagination__direction-button', 'iris-pagination__direction-button--first');
		firstButton.setAttribute('type', 'button');
		firstButton.setAttribute('aria-label', 'Go to first page');
		firstButton.innerHTML = '<span class="font-icon-angle-left-double" data-icon-size="md"></span>';

		firstButton.addEventListener('click', firstButtonClickHander);
		this._paginationButtons.push(firstButton);
		this._navigationElement.appendChild(firstButton);

		const backButton = document.createElement('button');
		backButton.classList.add('iris-pagination__direction-button', 'iris-pagination__direction-button--previous');
		backButton.setAttribute('type', 'button');
		backButton.setAttribute('aria-label', 'Go to previous page');
		backButton.innerHTML = '<span class="font-icon-angle-left" data-icon-size="md"></span>';

		backButton.addEventListener('click', backButtonClickHandler);
		backButton.addEventListener('keyup', backButtonKeyHandler);
		this._paginationButtons.push(backButton);
		this._navigationElement.appendChild(backButton);

		const textfieldContainer = document.createElement('div');
		textfieldContainer.classList.add('iris-pagination__textfield');

		this._paginationTextfield = document.createElement('input');
		this._paginationTextfield.setAttribute('type', 'number');
		this._paginationTextfield.setAttribute('min', '1');
		this._paginationTextfield.setAttribute('max', this._paginatedArray.length as any);
		this._paginationTextfield.setAttribute('aria-label', 'Current Page:');
		this._paginationTextfield.classList.add('iris-textfield__input');

		this._paginationTextfield.addEventListener('keyup', inputKeyPresshandler);
		this._paginationTextfield.addEventListener('click', inputClickHandler);
		textfieldContainer.appendChild(this._paginationTextfield);
		this._navigationElement.append(textfieldContainer);

		const totalPageCounter = document.createElement('span');
		totalPageCounter.classList.add('iris-pagination__total');
		totalPageCounter.innerText = `of ${this._paginatedArray.length}`;

		this._navigationElement.appendChild(totalPageCounter);

		const nextButton = document.createElement('button');
		nextButton.classList.add('iris-pagination__direction-button', 'iris-pagination__direction-button--next');
		nextButton.setAttribute('type', 'button');
		nextButton.setAttribute('aria-label', 'Go to next page');
		nextButton.innerHTML = '<span class="font-icon-angle-right" data-icon-size="md"></span>';

		nextButton.addEventListener('click', nextButtonClickHandler);
		nextButton.addEventListener('keyup', nextButtonKeyHandler);
		this._paginationButtons.push(nextButton);
		this._navigationElement.appendChild(nextButton);

		const lastButton = document.createElement('button');
		lastButton.classList.add('iris-pagination__direction-button', 'iris-pagination__direction-button--last');
		lastButton.setAttribute('type', 'button');
		lastButton.setAttribute('aria-label', 'Go to last page');
		lastButton.innerHTML = '<span class="font-icon-angle-right-double" data-icon-size="md"></span>';

		lastButton.addEventListener('click', lastButtonClickHander);
		this._paginationButtons.push(lastButton);
		this._navigationElement.appendChild(lastButton);

		this.currentPageIndex = this._initPageIndex;
	}

	public destroy() {
		if (this._paginationButtons) {
			this._paginationButtons.forEach((button: HTMLElement) => {
				button.remove();
			});
		}

		if (this._paginationTextfield) {
			this._paginationTextfield.remove();
		}

		this._navigationElement.innerHTML = '';
	}

	private static _factoryOptions: IComponentFactoryOptions = {
		defaultQuerySelector: '.iris-pagination',
		componentName: 'PaginationComponent'
	};

	public static factory = new GenericComponentFactory<PaginationComponent, IPaginationComponentOptions>(PaginationComponent, PaginationComponent._factoryOptions);
}
