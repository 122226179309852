
export class AccessabilityDetection {

	private _mouseClassName = 'iris-mouse-use-detected';
	private _keyboardClassName = 'iris-keyboard-use-detected';
	private _isKeyPressed = false;
	private _element = document.body;

	constructor() {
		this._element.classList.contains(this._keyboardClassName) ? this._handleKeyDown() : this._handleMouseDown();
		this._handleKeyUp();

		window.addEventListener('keyup', this._handleKeyUp);
	}

	public destroy() {
		window.removeEventListener('keydown', this._handleKeyDown);
		window.removeEventListener('mousedown', this._handleMouseDown);
		window.removeEventListener('touchstart', this._handleMouseDown);
		this._element.classList.remove(this._mouseClassName);
		this._element.classList.remove(this._keyboardClassName);
	}

	private _handleKeyDown = (e?: KeyboardEvent) => {
		// If this isn't a keyboard event then bail out. (chrome does this on autofill)
		if (e && !e.key) {
			return;
		}

		const key = (e && e.key.toLowerCase()) || null;
		const whitelistedTypes = ['INPUT', 'TEXTAREA'];
		const hasActiveElement = !!document.activeElement;
		const isActiveElementWhitelisted = whitelistedTypes.find((tagName) => tagName === document.activeElement.tagName);

		if (key !== 'tab' && hasActiveElement && isActiveElementWhitelisted ) {
			return;
		}

		this._isKeyPressed = true;
		this._element.classList.remove(this._mouseClassName);
		this._element.classList.add(this._keyboardClassName);
		window.removeEventListener('keydown', this._handleKeyDown);
		window.addEventListener('mousedown', this._handleMouseDown);
		window.addEventListener('touchstart', this._handleMouseDown);
	}

	private _handleKeyUp = () => {
		this._isKeyPressed = false;
	}

	private _handleMouseDown = () => {
		if (this._isKeyPressed) {
			return;
		}

		this._element.classList.add(this._mouseClassName);
		this._element.classList.remove(this._keyboardClassName);
		window.removeEventListener('mousedown', this._handleMouseDown);
		window.removeEventListener('touchstart', this._handleMouseDown);
		window.addEventListener('keydown', this._handleKeyDown);
	}

}
