import { ComponentFactory } from '../component-factory';
import * as dom from '../dom';
import * as utility from '../utility';
import { Expandable, IExpandableComponentOptions } from './expandable';
import { SyntheticButtonComponent } from './synthetic-button';

export interface ICollapsableComponentOptions extends IExpandableComponentOptions {
	targetElement: HTMLElement;
}

export class CollapsableComponentFactory extends ComponentFactory<CollapsableComponent, ICollapsableComponentOptions> {

	constructor() {
		super((element: HTMLElement, options?: ICollapsableComponentOptions) => {
			return new CollapsableComponent(element, Object.assign({ idPrefix: 'iris_collapsable', componentName: 'CollapsableComponent' }, options));
		}, { defaultQuerySelector: '[data-collapsable]', componentName: 'CollapsableComponent'});
	}

	public componentForElement(element: HTMLElement): CollapsableComponent {
		let component = super.componentForElement(element);

		if (element && !component) {
			Object.values(this.components)
				.forEach((componentArray: CollapsableComponent[]) => {
					componentArray.forEach((cachedComponent) => {
						if (cachedComponent.getOptions().componentName === this.componentName &&
							cachedComponent.targetElement.id === element.id) {
								component = cachedComponent;
						}
					});
				});
		}

		return component || null;
	}
}

export class CollapsableComponent extends Expandable {
	private _closeElement: HTMLElement;

	constructor(srcElement: HTMLElement, options?: ICollapsableComponentOptions) {
		super(srcElement, options);

		this.collapseStartClass = 'iris-collapsable--collapse-start';
		this.collapseTransitionClass = 'iris-collapsable--collapsing';
		this.collapseEndClass = 'iris-collapsable--collapsed';

		this.expandStartClass = 'iris-collapsable--expand-start';
		this.expandTransitionClass = 'iris-collapsable--expanding';
		this.expandEndClass = 'iris-collapsable--expanded';

		this.idPrefix = 'iris_collapsable';
		this.defaultQuery = '.iris-collapsable';
		this.queryAttribute = 'data-collapsable';

		this.targetElement = options.targetElement || this.targetElement;

		this.sourceElement.addEventListener('click', this._clickHandler);

		if (this.sourceElement.tagName !== 'BUTTON') {
			SyntheticButtonComponent.factory.init(this.sourceElement);
		}

		if (this.targetElement) {
			this._closeElement = this.targetElement.querySelector('[data-close]') as HTMLElement;
		}

		if (this._closeElement) {
			this._closeElement.addEventListener('click', this._closeButtonHandler);

			if (this._closeElement.tagName !== 'BUTTON') {
				SyntheticButtonComponent.factory.init(this._closeElement);
			}
		}

		if (this.sourceElement.parentElement.matches('.iris-record')) {
			this.sourceElement.parentElement.classList.add('iris-record--collapsable');
		}

		this.sourceElement.addEventListener('expandstart', this._expandStartHandler);
		this.sourceElement.addEventListener('expandend', this._expandEndHandler);
		this._toggleChevron();
	}

	public destroy() {
		super.destroy();

		if (this.sourceElement) {
			this.sourceElement.removeEventListener('click', this._clickHandler);
			this.sourceElement.removeEventListener('expandstart', this._expandStartHandler);
			this.sourceElement.removeEventListener('expandend', this._expandEndHandler);

			SyntheticButtonComponent.factory.destroy(this.sourceElement);
		}

		if (this._closeElement) {
			this._closeElement.removeEventListener('click', this._closeButtonHandler);

			SyntheticButtonComponent.factory.destroy(this._closeElement);
		}
	}

	get group() {
		return this.sourceElement.getAttribute('data-collapsable-group');
	}

	set group(str: string) {
		this.sourceElement.setAttribute('data-collapsable-group', str);
	}

	private _clickHandler: EventListener = (event: Event) => {
		const target = event.target as HTMLElement;
		const elementType = target.tagName.toUpperCase();
		const clickForMe = target === this.sourceElement || (target !== this.sourceElement && elementType !== 'BUTTON' && elementType !== 'A');

		if (clickForMe) {
			this.toggle();
			event.stopPropagation();
		}

		return true;
	}

	private _closeButtonHandler: EventListener = (event: Event) => {
		this.expanded = false;
	}

	private _expandStartHandler: EventListener = (event: CustomEvent) => {
		this._toggleChevron();

		if (this.sourceElement.parentElement.matches('.iris-record')) {
			this._toggleRecordExpandedClass();
		}

		const detail = Object.assign({}, event.detail, { component: this });
		dom.dispatchEvent(this.sourceElement, 'iris.collapse.start', detail);
	}

	private _expandEndHandler: EventListener = (event: CustomEvent) => {
		const detail = Object.assign({}, event.detail, { component: this });
		dom.dispatchEvent(this.sourceElement, 'collapse', detail);
		dom.dispatchEvent(this.sourceElement, 'iris.collapse.end', detail);
	}

	private _toggleChevron() {
		const chevronElements = utility.toArray(this.sourceElement.querySelectorAll('.iris-chevron'));
		chevronElements.forEach((chevronElement: HTMLElement) => {
			chevronElement.classList.toggle('iris-chevron--up', this.expanded);
			chevronElement.classList.toggle('iris-chevron--down', this.collapsed);
		});
	}

	private _toggleRecordExpandedClass() {
		this.sourceElement.parentElement.classList.toggle('iris-record--collapsable-expanded', this.expanded);
	}

	public static factory = new CollapsableComponentFactory();
}
