export function createKeypressEventListener(handlers: IKeyPressHandlers): EventListener {
	return function(e: KeyboardEvent) {
		// If this isn't a keyboard event then bail out. (chrome does this on autofill)
		if (e && !e.key) {
			return;
		}

		const keyCode = e.keyCode.toString();
		const key = e.key.toLowerCase();
		const altKeyName = (KEY_CODE_NAME_MAP[keyCode] || key).toLowerCase();
		let handlerKey = Object.keys(handlers).find((prop: string) => {
			const handlerName = prop.toLowerCase();
			return handlerName === keyCode || handlerName === key || handlerName === altKeyName;
		});

		// if the key isn't in the list but we have 'default', we will run that instead.
		if (!handlerKey && handlers.default) {
			handlerKey = 'default';
		}

		if (handlerKey) {
			const keyHandler = handlers[handlerKey].bind(this);
			const shouldBubble = keyHandler(e);
			if (!shouldBubble) {
				e.preventDefault();
				e.stopPropagation();
			}

			return shouldBubble;
		}

		return true;
	};
}

export function simulateClickKeypressHandler(element?: HTMLElement) {
	function eventHandler(event: Event) {
		const target = element || (this as HTMLElement);

		target.click();
		return false;
	}

	return createKeypressEventListener({
		spacebar: eventHandler,
		enter: eventHandler,
	});
}

export const KEY_CODE_NAME_MAP: { [key: string]: string } = {
	13: 'Enter',
	27: 'Escape',
	32: 'Spacebar',
	37: 'Left',
	38: 'Up',
	39: 'Right',
	40: 'Down',
	46: 'Del',
	91: 'OS',
	145: 'Scroll',
};

export interface IKeyPressHandlers {
	[keyName: string]: EventListener;
}
