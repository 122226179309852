import * as utility from '../utility';
import { ListItem } from './list-item';

const ariaSelectedDeprecationWarning = utility.once(function(element: HTMLElement) {
	// tslint:disable-next-line no-console
	console.warn('Using "aria-selected" for selecting an option for DropdownComponent will be deprecated in next major version. Please use the "selected" attribute instead.', element);
});

const ariaDisabledDeprecationWarning = utility.once(function(element: HTMLElement) {
	// tslint:disable-next-line no-console
	console.warn('Using "aria-disabled" for disabling an option for DropdownComponent will be deprecated in next major version. Please use the "disabled" attribute instead.', element);
});

const defaultBlackListAttributes = ['id', 'data-bind'];

export interface IListOptionConfig {
	blackListAttributes: string[];
}

export class ListOption {
	private _displayListItem: ListItem;
	private _element: HTMLElement;
	private _blackListAttributes: string[];

	constructor(element: HTMLElement, options?: IListOptionConfig) {
		this._element = element;

		let dupes: HTMLElement[] = [];

		if (!this.element.id) { // Stops unecessary mutation.
			this.element.id = utility.generateUniqueId('iris_option');
		} else {
			dupes = utility.toArray(document.querySelectorAll(`#${this.element.id}`));
		}

		// The duplicate ID scenario can happen in situations such as
		// using knockout without a template. Iris fires adding a generated id
		// then knockout copies it in a foreach. The dropdown then gets confused.
		if (dupes.length > 1) {
			const isGeneratedId = this.element.id.startsWith('iris_option_');
			const isFirst = dupes.indexOf(this.element) === 0;

			if (!isGeneratedId) {
				// We didn't do it! blow up!
				throw new Error(`The element ID ${this.element.id} is not unique.`);
			}

			if (!isFirst) {
				// We are going to try and recover since we *probably* generated the ID.
				this.element.id = utility.generateUniqueId('iris_option');
			}
		}

		if (this.element.dataset.value === null || typeof this.element.dataset.value === 'undefined') {
			this.element.dataset.value = this.element.innerText.trim();
		}


		this._blackListAttributes = defaultBlackListAttributes.concat((options && options.blackListAttributes) || []);

		// tslint:disable-next-line no-unused-expression
		this.displayListItem;
	}

	get displayListItem(): ListItem {
		if (this._displayListItem) {
			return this._displayListItem;
		}

		const displayListItemElement = ListOption.createListOptionElement(this.value, '');
		displayListItemElement.id = this.element.id + '_display_list_item';
		displayListItemElement.dataset.optionId = this.element.id;

		const checkboxContainer = document.createElement('DIV');
		checkboxContainer.id = displayListItemElement.id + '_checkbox';
		checkboxContainer.classList.add('iris-option__multiselect-checkbox-container');
		checkboxContainer.innerHTML = `<div class="iris-checkbox">
			<input class="iris-checkbox__input" type="checkbox"  />
			<label class="iris-checkbox__label">
			</label>
		</div>`;
		displayListItemElement.prepend(checkboxContainer);

		const displayListItemContentElement = displayListItemElement.querySelector('.iris-list-item__content');
		displayListItemContentElement.innerHTML = this._updateDisplayListItemContent().innerHTML;

		this._displayListItem = new ListItem(displayListItemElement);
		return this._displayListItem;
	}

	get element(): HTMLElement {
		return this._element;
	}

	get selectedViewElement(): HTMLElement {
		const node = this.element.querySelector('.iris-option__selected-view') || this.element;
		const phoNode = node.cloneNode(true) as HTMLElement;
		phoNode.innerHTML = phoNode.innerHTML.replace(/<!--([\s\S]*?)-->/g, '');

		this._blackListAttributes.forEach((attribute) => {
			utility.toArray(phoNode.querySelectorAll(`[${attribute}]`)).forEach((element) => {
				element.removeAttribute(attribute);
			});
		});

		return phoNode;
	}

	get id(): string {
		return this.element.id;
	}

	set id(id: string) {
		this.element.id = id;
		this.displayListItem.id = this.element.id + '_display_list_item';
	}

	get selected(): boolean {
		if (this.element.hasAttribute('aria-selected')) {
			ariaSelectedDeprecationWarning(this.element);
		}
		return this.element.hasAttribute('selected') || (this.element.getAttribute('aria-selected') || '').toLowerCase() === 'true';
	}

	set selected(bool: boolean) {
		if (bool) {
			this.element.setAttribute('selected', 'selected');
		} else {
			this.element.removeAttribute('selected');
		}

		if (this.element.hasAttribute('aria-selected')) {
			this.element.setAttribute('aria-selected', bool.toString());
		}

	}

	get active(): boolean {
		return this.element.getAttribute('data-active') === 'true';
	}

	set active(bool: boolean) {
		this.element.dataset.active = bool.toString();
	}

	get disabled(): boolean {
		if (this.element.hasAttribute('aria-disabled')) {
			ariaDisabledDeprecationWarning(this.element);
		}

		return this.element.hasAttribute('disabled') || (this.element.getAttribute('aria-disabled') || '').toLowerCase() === 'true';
	}

	set disabled(bool: boolean) {

		if (bool) {
			this.element.setAttribute('disabled', 'disabled');
		} else {
			this.element.removeAttribute('disabled');
		}

		if (this.element.hasAttribute('aria-disabled')) {
			this.element.setAttribute('aria-disabled', bool.toString());
		}
	}

	get text(): string {
		const textElement = this.selectedViewElement || this.element;
		let text = this.element.getAttribute('aria-label') || textElement.innerText;

		// Element my still be hidden. If so, copy the element and take the text from there.
		if (!text) {
			const div = document.createElement('DIV');
			div.innerHTML = this.element.innerHTML;
			text = div.innerText;
			div.remove();
		}

		return text.trim();
	}

	get value(): string {
		return this.element.dataset.value || '';
	}

	set value(value: string) {
		this.element.dataset.value = value;
	}

	public updateListItem() {
		utility.toArray(this.element.attributes).map((attribute: Attr) => {
			const key = attribute.name.toLowerCase();
			const value = attribute.value;
			if (key.indexOf('aria-') === -1) {
				return;
			}
			this.displayListItem.element.setAttribute(key, value);
		});

		this.displayListItem.selected = this.selected;
		this.displayListItem.disabled = this.disabled;
		this.displayListItem.value = this.value;
		this.displayListItem.active = this.active;

		const displayListItemContentElement = this.displayListItem.element.querySelector('.iris-list-item__content');
		displayListItemContentElement.innerHTML = this._updateDisplayListItemContent().innerHTML;
	}

	private _updateDisplayListItemContent() {
		const displayContent = this.element.querySelector('.iris-list-item__content') || this.element;
		const displayContentElement = document.createElement('DIV');

		displayContentElement.innerHTML = displayContent.innerHTML.replace(/<!--([\s\S]*?)-->/g, '');

		utility.toArray(displayContentElement.querySelectorAll('.iris-option__multiselect-checkbox-container, .iris-option__selected-view'))
			.forEach((element) => element.remove());

		this._blackListAttributes.forEach((attribute) => {
			utility.toArray(displayContentElement.querySelectorAll(`[${attribute}]`)).forEach((element) => {
				element.removeAttribute(attribute);
			});
		});

		return displayContentElement;
	}

	public static createListOptionElement(value: string, textOrHtmlElement: string | HTMLElement, selectedView?: HTMLElement): HTMLLIElement {
		const listOption = document.createElement('LI') as HTMLLIElement;
		listOption.classList.add('iris-list-item', 'iris-option');
		listOption.setAttribute('data-value', value);

		const listContent = document.createElement('DIV') as HTMLElement;
		listContent.classList.add('iris-list-item__content');

		if (utility.isString(textOrHtmlElement)) {
			listContent.innerText = textOrHtmlElement as string;
		} else {
			listContent.appendChild(textOrHtmlElement as HTMLElement);
		}

		listOption.append(listContent);

		if (selectedView) {
			const selectedViewElement = document.createElement('DIV');
			selectedViewElement.classList.add('iris-option__selected-view');
			selectedViewElement.appendChild(selectedView);

			listOption.appendChild(selectedViewElement);
		}

		return listOption;
	}
}
