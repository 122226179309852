import * as dom from '../dom';
import { createKeypressEventListener, IKeyPressHandlers } from '../keypress-handler';
import * as utility from '../utility';

export interface ITabPosition {
	left: number;
	right: number;
}

export class TabComponent {
	public _element: HTMLElement;
	private _tabListElement: HTMLElement;
	private _linkElement: HTMLElement;

	constructor(targetElement: HTMLElement, tabListElement: HTMLElement) {
		this._element = targetElement;
		this._tabListElement = tabListElement;
		this._element.setAttribute('role', 'tab');
		this._element.tabIndex = 0;

		if (!this._element.id) {
			this._element.id = utility.generateUniqueId('iris_tab');
		}

		this._linkElement = this.element.querySelector('.iris-tabs__link') as HTMLElement;
		if (this._linkElement) {
			this._linkElement.tabIndex = -1;
		}

		this.active = this.active;

		this.element.addEventListener('click', this._clickHandler);
		this.element.addEventListener('keydown', this._keypressHandler);
	}

	get active(): boolean {
		return this._element.getAttribute('aria-selected') === 'true';
	}

	set active(bool: boolean) {
		this._element.setAttribute('aria-selected', bool.toString());
	}

	get element(): HTMLElement {
		return this._element;
	}

	get id(): string {
		return this._element.id;
	}

	get position(): ITabPosition {
		const tabRect = this._element.getBoundingClientRect();
		const tabInnerRect = this._tabListElement.getBoundingClientRect();

		return {
			left: tabRect.left - tabInnerRect.left,
			right: tabRect.right - tabInnerRect.right,
		};
	}

	get text(): string {
		return this.element.getAttribute('aria-label') || this.element.innerText;
	}

	public destroy() {
		if (this.element) {
			this.element.removeEventListener('click', this._clickHandler);
			this.element.removeEventListener('keydown', this._keypressHandler);
		}
	}

	private _fireActive() {
		if (this.active) {
			return;
		}

		this.active = true;
		dom.dispatchEvent(this.element, 'activate', { component: this });

		if (this._linkElement) {
			this._linkElement.click();
		}
	}

	private _clickHandler = (event: Event) => {
		this._fireActive();
	}

	private _keypressHandler = (event: Event) => {
		return createKeypressEventListener({
			spacebar: () => { this._fireActive(); },
			enter: () => { this._fireActive(); },
			tab: () => {
				return true;
			},
		} as IKeyPressHandlers)(event);
	}

	public static createTabElement(textOrHtmlElement: string | HTMLElement, link?: string) {
		const tabElement = document.createElement('LI');
		tabElement.classList.add('iris-tabs__list-item');
		const tabLink = document.createElement('A');
		if (link) {
			tabLink.setAttribute('href', link);
		}
		if (utility.isString(textOrHtmlElement)) {
			tabLink.innerText = textOrHtmlElement as string;
		} else {
			tabLink.appendChild(textOrHtmlElement as HTMLElement);

		}
	}
}
