import { IComponentOptions } from '../component';
import { ComponentFactory } from '../component-factory';
import * as dom from '../dom';
import { Expandable } from './expandable';
import { SyntheticButtonComponent } from './synthetic-button';

const PLACEMENT = {
	top: 'top',
	right: 'right',
	bottom: 'bottom',
	left: 'left',
};

const INDICATOR_POSITION = {
	center: 'center',
	end: 'end',
	start: 'start',
	none: 'none',
};

export interface IPopoverComponentOptions extends IComponentOptions {
	targetElement: HTMLElement;
}

export class PopoverComponentFactory extends ComponentFactory<PopoverComponent, IPopoverComponentOptions> {

	constructor() {
		super((element: HTMLElement, options?: IPopoverComponentOptions) => {
			return new PopoverComponent(element, Object.assign({ idPrefix: 'iris_popover', componentName: 'PopoverComponent' }, options));
		}, { defaultQuerySelector: '[data-popover]', componentName: 'PopoverComponent'});

		dom.ready(() => {
			document.addEventListener('focusin', this.documentClickHandler);
			document.addEventListener('click', this.documentClickHandler);
		});
	}

	public componentForElement(element: HTMLElement): PopoverComponent {

		let component = super.componentForElement(element);

		if (element && !component) {
			Object.values(this.components)
				.forEach((componentArray: PopoverComponent[]) => {
					componentArray.forEach((cachedComponent) => {
						if (cachedComponent.getOptions().componentName === this.componentName &&
							cachedComponent.targetElement.id === element.id) {
								component = cachedComponent;
						}
					});
				});
		}

		return component || null;
	}

	private documentClickHandler = (event: MouseEvent | FocusEvent) => {
		const target = event.target as HTMLElement;

		Object.values(this.components)
			.forEach((componentArray: PopoverComponent[]) => {
				componentArray.forEach((cachedComponent) => {
					if (cachedComponent.getOptions().componentName === this.componentName &&
						cachedComponent.expanded &&
						cachedComponent.closeOnLeave &&
						!(cachedComponent.targetElement.contains(target) || cachedComponent.sourceElement.contains(target))) {
							cachedComponent.collapsed = true;
						}
				});
			});
	}
}


export class PopoverComponent extends Expandable {
	private _closeElement: HTMLElement;
	private _popper: Popper;
	private _mutationObserver: MutationObserver = new MutationObserver(() => {
		if (this._popper) {
			this._popper.options.placement = this.placement as Popper.Placement;
		}

		if (this.targetElement) {
			this.targetElement.setAttribute('x-indicator', this.indicator);
		}
	});


	constructor(srcElement: HTMLElement, options?: IPopoverComponentOptions) {
		super(srcElement, options);

		this.collapseStartClass = 'iris-popover--collapse-start';
		this.collapseTransitionClass = 'iris-popover--collapsing';
		this.collapseEndClass = 'iris-popover--collapsed';

		this.expandStartClass = 'iris-popover--expand-start';
		this.expandTransitionClass = 'iris-popover--expanding';
		this.expandEndClass = 'iris-popover--expanded';

		this.idPrefix = 'iris_popover';
		this.defaultQuery = '.iris-popover';
		this.queryAttribute = 'data-popover';

		this.sourceElement.addEventListener('click', this._clickHandler);
		this.sourceElement.addEventListener('expandstart', this._expandStartHandler);
		this.sourceElement.addEventListener('expandend', this._expandEndHandler);

		this._mutationObserver.observe(this.sourceElement, { attributes: true });

		this.targetElement = options.targetElement || this.targetElement;

		this.targetElement.setAttribute('role', 'dialog');

		if (this.sourceElement.tagName !== 'BUTTON') {
			SyntheticButtonComponent.factory.init(this.sourceElement);
		}

		if (this.targetElement) {
			this._closeElement = this.targetElement.querySelector('[data-close]') as HTMLElement;
		}

		if (this._closeElement) {
			this._closeElement.addEventListener('click', this._closeButtonHandler);

			if (this._closeElement.tagName !== 'BUTTON') {
				SyntheticButtonComponent.factory.init(this._closeElement);
			}
		}
	}

	get placement(): string {
		let placement = Object.values(PLACEMENT).find((value) => value === this.sourceElement.getAttribute('data-placement')) || 'auto';
		const indicator = this.indicator;

		if (indicator === 'start' || indicator === 'end') {
			placement = placement + '-' + indicator;
		}

		return placement;
	}

	get indicator(): string {
		return Object.values(INDICATOR_POSITION).find((value) => value === this.sourceElement.getAttribute('data-indicator')) || 'center';
	}

	get closeOnLeave(): boolean {
		return this.targetElement.getAttribute('data-close-on-leave') === '' || this.targetElement.getAttribute('data-close-on-blur') === 'true';
	}

	get staticPlacement(): boolean {
		return this.sourceElement.getAttribute('data-static-placement') === '' || this.sourceElement.getAttribute('data-static-placement') === 'true';
	}

	public destroy() {
		super.destroy();

		if (this.sourceElement) {
			this.sourceElement.removeEventListener('click', this._clickHandler);
			this.sourceElement.removeEventListener('expandstart', this._expandStartHandler);
			this.sourceElement.removeEventListener('expandend', this._expandEndHandler);

			SyntheticButtonComponent.factory.destroy(this.sourceElement);
		}

		if (this._closeElement) {
			this._closeElement.removeEventListener('click', this._closeButtonHandler);

			SyntheticButtonComponent.factory.destroy(this._closeElement);
		}

		if (this._popper) {
			this._popper.destroy();
		}

		this._mutationObserver.disconnect();
	}

	private _clickHandler: EventListener = (event: Event) => {
		const target = event.target as HTMLElement;
		const elementType = target.tagName.toUpperCase();
		const clickForMe = target === this.sourceElement || (target !== this.sourceElement && elementType !== 'BUTTON' && elementType !== 'A');

		if (clickForMe) {
			this.toggle();
			event.stopPropagation();
		}

		return true;
	}

	private _closeButtonHandler: EventListener = (event: Event) => {
		this.expanded = false;
	}

	private _expandStartHandler: EventListener = (event: CustomEvent) => {
		if (!this.targetElement) {
			return;
		}

		// If the popover is expanding, create a new popper instance
		if (this.expanded === true) {
			const popperConfig: Popper.PopperOptions = {
				placement: this.placement as Popper.Placement
			};

			if (this.staticPlacement) {
				popperConfig.modifiers = {
					flip: {
						enabled: false
					}
				};
			}

			this._popper = new Popper(this.sourceElement, this.targetElement, popperConfig);
		}

		if (this._popper) {
			this._popper.scheduleUpdate();
		}
	}

	private _expandEndHandler: EventListener = (event: CustomEvent) => {
		if (!this.targetElement) {
			return;
		}

		// If the popover is closing, destroy the popper instance
		if (this.collapsed === true) {
			this._popper.destroy();

			// Also set this to null so that the component destroy method
			// doesn't try to destroy it.
			this._popper = null;
		}
		// Check if data-focus-target exists
		if (this.expanded && this.targetElement.hasAttribute('data-focus-target')) {
			// If exists, this.targetElement.querySelector for element that matches data-focus-target value
			const focusTarget = this.targetElement.getAttribute('data-focus-target');
			const queriedTarget = this.targetElement.querySelector(focusTarget) as HTMLElement;
			// Focus element data-focus-target
			setTimeout(() => queriedTarget.focus(), 0);
		}
	}

	public static PLACEMENT = PLACEMENT;
	public static INDICATOR_POSITION = INDICATOR_POSITION;
	public static factory = new PopoverComponentFactory();

}
