import { Component, IComponentOptions } from '../component';
import * as dom from '../dom';
import { ITransitionOptions, transitionRunner } from '../transition-handler';
import * as utility from '../utility';

export interface IExpandableComponentOptions extends IComponentOptions {
	targetElement: HTMLElement;
}

export class Expandable extends Component {
	private _originalMaxHeight: string = null;
	private _targetQuery: string;

	public collapseStartClass: string;
	public collapseTransitionClass: string;
	public collapseEndClass: string;

	public expandStartClass: string;
	public expandTransitionClass: string;
	public expandEndClass: string;

	public idPrefix: string = 'iris_expandable';
	public queryAttribute: string = 'data-expandable';
	public defaultQuery: string = '.iris-expandable';
	public targetAttribute: string = 'aria-controls';


	constructor(sourceElement: HTMLElement, options?: IExpandableComponentOptions) {
		super(sourceElement, options);

		this.sourceElement.setAttribute('aria-expanded', this.expanded.toString());
		this.sourceElement.setAttribute('tabindex', '0');
	}

	get sourceElement(): HTMLElement {
		return this.element;
	}

	get targetElement(): HTMLElement {
		const targetElement = document.getElementById(this.sourceElement.getAttribute('aria-controls'));

		if (targetElement) {
			return targetElement;
		}

		const targetQuery = this.sourceElement.getAttribute(this.queryAttribute) || this.defaultQuery;
		return dom.relatedElementByQuery(this.sourceElement, targetQuery);
	}

	set targetElement(targetElement: HTMLElement) {
		const currentTarget = this.targetElement;

		// clean up the old
		this._originalMaxHeight = null;
		if (currentTarget) {
			this._cleanClasses(currentTarget);
			currentTarget.removeEventListener('transitionend', this._transitionEndHandler);
			currentTarget.removeEventListener('keydown', this._escHandler);
		}

		if (targetElement) {
			targetElement.addEventListener('keydown', this._escHandler);
			targetElement.id = targetElement.id || utility.generateUniqueId(this.idPrefix);
			targetElement.setAttribute('tabindex', '-1');
			targetElement.classList.toggle(this.collapseEndClass, this.collapsed);
			targetElement.classList.toggle(this.expandEndClass, this.expanded);

			this.sourceElement.setAttribute('aria-controls', targetElement.id);

			this._originalMaxHeight = targetElement.style.maxHeight || null;
		} else {
			this.sourceElement.removeAttribute('aria-controls');
		}

		const targetChangeEvent = new CustomEvent('targetchange', {
			bubbles: true,
			cancelable: true,
			detail: {
				oldTargetElement: currentTarget,
				targetElement,
				component: this,
			},
		});

		this.sourceElement.dispatchEvent(targetChangeEvent);
	}

	get collapsed(): boolean {
		return !this.expanded;
	}

	set collapsed(bool: boolean) {
		this.expanded = !bool;
	}

	get expanded(): boolean {
		return (this.sourceElement.getAttribute('aria-expanded') || '') === 'true';
	}

	set expanded(bool: boolean) {
		if (this.expanded === bool && this.sourceElement.hasAttribute('aria-expanded')) {
			return;
		}

		this.sourceElement.setAttribute('aria-expanded', bool.toString());

		// Dispatch expandstart event.
		const expandStartEvent = new CustomEvent('expandstart', {
			bubbles: true,
			cancelable: true,
			detail: {
				sourceElement: this.sourceElement,
				targetElement: this.targetElement,
				expanded: bool,
				collapsed: !bool,
				component: this,
			},
		});

		this.sourceElement.dispatchEvent(expandStartEvent);

		// Clean up the element and add max-height property
		this._cleanClasses(this.targetElement);
		this.targetElement.style.maxHeight = this._originalMaxHeight || getHeight(this.targetElement) + 'px';

		// Transition steps
		let expandableTransitionSteps: ITransitionOptions[];

		if (bool) {
			expandableTransitionSteps = [
				{
					addClassString: this.expandStartClass,
					type: 'immediate',
				},
				{
					addClassString: this.expandTransitionClass,
					removeClassString: this.expandStartClass,
					type: 'transition',
				},
				{
					afterTransitionEventName: 'iris.expandable.transition.complete',
					addClassString: this.expandEndClass,
					removeClassString: this.expandTransitionClass,
					type: 'immediate',
				},
			];
		} else {
			expandableTransitionSteps = [
				{
					addClassString: this.collapseStartClass,
					type: 'immediate',
				},
				{
					addClassString: this.collapseTransitionClass,
					removeClassString: this.collapseStartClass,
					type: 'transition',
				},
				{
					afterTransitionEventName: 'iris.expandable.transition.complete',
					addClassString: this.collapseEndClass,
					removeClassString: this.collapseTransitionClass,
					type: 'immediate',
				},
			];
		}

		this.targetElement.addEventListener('iris.expandable.transition.complete', this._transitionEndHandler);
		transitionRunner(this.targetElement, expandableTransitionSteps);

		if (bool && this.focusable) {
			this.targetElement.focus();
		} else if (!bool && this.returnFocus) {
			this.sourceElement.focus();
		}
	}

	get focusable() {
		return (this.sourceElement.getAttribute('data-focus') || 'false') === 'true';
	}

	set focusable(bool: boolean) {
		this.sourceElement.setAttribute('data-focus', bool.toString());
	}

	get returnFocus() {
		return this.targetElement.hasAttribute('data-return-focus');
	}

	set returnFocus(bool: boolean) {
		this.targetElement.toggleAttribute('data-return-focus', bool);
	}

	public toggle() {
		this.expanded = !this.expanded;
	}

	public destroy() {
		if (this.sourceElement) {
			this.sourceElement.removeAttribute('aria-expanded');
		}

		const targetElement = this.targetElement;

		if (targetElement) {
			this._cleanClasses(targetElement);
			targetElement.removeEventListener('transitionend', this._transitionEndHandler);
			targetElement.removeEventListener('keydown', this._escHandler);
		}
	}

	// remove any classes that don't need to be there.
	private _cleanClasses(element: HTMLElement) {
		const classNames = [
			this.collapseStartClass,
			this.collapseTransitionClass,
			this.collapseEndClass,
			this.expandStartClass,
			this.expandTransitionClass,
			this.expandEndClass,
		].filter((className) => !!className); // remove any class Names that are null from the list.

		element.classList.remove(...classNames);
	}

	private _escHandler = (event: KeyboardEvent) => {
		if (event.keyCode === 27) {
			this.toggle();
		}
	}

	private _transitionEndHandler = (event: Event) => {
		this.targetElement.removeEventListener('iris.expandable.transition.complete', this._transitionEndHandler);
		this.targetElement.style.maxHeight = this._originalMaxHeight;

		const expandEndEvent = new CustomEvent('expandend', {
			bubbles: true,
			cancelable: true,
			detail: {
				sourceElement: this.sourceElement,
				targetElement: this.targetElement,
				expanded: this.expanded,
				collapsed: this.collapsed,
				component: this,
			},
		});

		this.sourceElement.dispatchEvent(expandEndEvent);
	}
}

function getHeight(el: HTMLElement): number {
	const elComputedStyle = window.getComputedStyle(el);
	const elComputedDisplay = elComputedStyle.display;
	const elComputedMaxHeight = elComputedStyle.maxHeight.replace('px', '').replace('%', '');

	const elStyleDisplay = el.style.display || null;
	const elStyleVisibility = el.style.visibility || null;
	const elStylePosition = el.style.position || null;

	let wantedHeight = 0;

	// if its not hidden we just return normal height
	if (elComputedDisplay !== 'none' && elComputedMaxHeight !== '0') {
		return el.offsetHeight;
	}

	// the element is hidden so:
	// making the el block so we can meassure its height but still be hidden

	el.style.position = 'absolute';
	el.style.visibility = 'hidden';
	el.style.display = 'block';

	wantedHeight = el.offsetHeight;

	// reverting to the original values
	el.style.display = elStyleDisplay;
	el.style.position = elStylePosition;
	el.style.visibility = elStyleVisibility;

	return wantedHeight;
}
