import * as dom from '../dom';
import * as utility from '../utility';

export class TabNavigationComponent {
	private _element: HTMLElement;

	constructor(direction: string, className: string, iconClassName: string | string[]) {
		const iconClassNames = Array.isArray(iconClassName) ? iconClassName : utility.splitAttributeToList(iconClassName);

		const buttonElement = document.createElement('button');
		buttonElement.classList.add('iris-tabs__nav-button', 'iris-button', 'iris-button--ghost', className);
		buttonElement.setAttribute('aria-hidden', 'true');
		buttonElement.tabIndex = -1;

		const iconElement = document.createElement('span');
		iconElement.classList.add('iris-button__icon');
		iconElement.classList.add(...iconClassNames);

		buttonElement.append(iconElement);

		buttonElement.addEventListener('click', () => {
			dom.dispatchEvent(buttonElement, 'navPressed', { direction, component: this });
		});

		this._element = buttonElement;
	}

	get element(): HTMLElement {
		return this._element;
	}

}
