import * as utility from './utility';

export interface IRegions { [region: string]: RegionSettings; }
export type RegionLike = {[S in keyof RegionSettings]?: RegionSettings[S]}; // validate some JSON that is Region Like

export class RegionSettings {
	public 'dropdown.sr.focused': string = '{0}, {1} Combo box.';
	public 'dropdown.sr.selected': string = '{0} selected.';
	public 'dropdown.sr.unselected': string = '{0} unselected.';
	public 'dropdown.sr.active': string = '{0}, active.';
	public 'dropdown.sr.disabled': string = '{0}, active, disabled.';
	public 'dropdown.multiselect.selected': string = '{0} items selected';

	public 'calendar.dayofweekabbr.sunday': string = 'sun';
	public 'calendar.dayofweekabbr.monday': string = 'mon';
	public 'calendar.dayofweekabbr.tuesday': string = 'tue';
	public 'calendar.dayofweekabbr.wednesday': string = 'wed';
	public 'calendar.dayofweekabbr.thursday': string = 'thu';
	public 'calendar.dayofweekabbr.friday': string = 'fri';
	public 'calendar.dayofweekabbr.saturday': string = 'sat';

	public 'calendar.month.january': string = 'January';
	public 'calendar.month.february': string = 'February';
	public 'calendar.month.march': string = 'March';
	public 'calendar.month.april': string = 'April';
	public 'calendar.month.may': string = 'May';
	public 'calendar.month.june': string = 'June';
	public 'calendar.month.july': string = 'July';
	public 'calendar.month.august': string = 'August';
	public 'calendar.month.september': string = 'September';
	public 'calendar.month.october': string = 'October';
	public 'calendar.month.november': string = 'November';
	public 'calendar.month.december': string = 'December';

	public 'calendar.monthabbr.january': string = 'Jan';
	public 'calendar.monthabbr.february': string = 'Feb';
	public 'calendar.monthabbr.march': string = 'Mar';
	public 'calendar.monthabbr.april': string = 'Apr';
	public 'calendar.monthabbr.may': string = 'May';
	public 'calendar.monthabbr.june': string = 'Jun';
	public 'calendar.monthabbr.july': string = 'Jul';
	public 'calendar.monthabbr.august': string = 'Aug';
	public 'calendar.monthabbr.september': string = 'Sep';
	public 'calendar.monthabbr.october': string = 'Oct';
	public 'calendar.monthabbr.november': string = 'Nov';
	public 'calendar.monthabbr.december': string = 'Dec';

	public 'contentheader.item.ascendinglabel' = '{0} (ascending)';
	public 'contentheader.item.descendinglabel' = '{0} (descending)';
	public 'contentheader.item.neutrallabel' = '{0}';
	public 'contentheader.item.ascendingdirections' = 'Activate button to sort by {0} ascending.';
	public 'contentheader.item.descendingdirections' = 'Activate button to sort by {0} descending.';

	public 'list.sr.active': string = '{0}, active.';
	public 'list.sr.disabled': string = '{0}, active, disabled.';
	public 'list.sr.selected': string = '{0} selected.';
	public 'list.sr.unselected': string = '{0} unselected.';

	public 'tab.sr.selected': string = '{0} selected.';

	constructor(settings = {} as RegionLike) {
		this.apply(settings);
	}

	public apply(settings: RegionLike) {
		Object.keys(this).forEach((key) => {
			const setting = (settings as any)[key];

			if (typeof setting !== 'undefined') {
				(this as any)[key] = setting;
			}
		});

		return this;
	}

	public format(key: keyof RegionSettings, ...args: string[]) {
		return utility.format((this as any)[key], ...args);
	}

	public static regions: IRegions = {
		en: new RegionSettings(),
	};

	// TODO: Attempt to know what language the user is using and pick it by default if it exists in the regions.
	public static defaultRegionId = 'en';

	public static get defaultRegion(): RegionSettings {
		return RegionSettings.regions[RegionSettings.defaultRegionId];
	}

	// Trying to make Regional strings as easy as possible
	// These functions mimic the Object's but point to the default region.

	public static format(key: keyof RegionSettings, ...args: string[]) {
		return RegionSettings.defaultRegion.format(key, ...args);
	}

	public static apply(settings: RegionLike) {
		return RegionSettings.defaultRegion.apply(settings);
	}
}
