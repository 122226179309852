import { Component, IComponentOptions } from '../component';
import { GenericComponentFactory, IComponentFactoryOptions } from '../component-factory';
import { toArray } from '../utility';
import { ContentHeaderItem } from './content-header-item';

export interface IContentHeaderOptions extends IComponentOptions {
	beforeEventName: string;
	beforeParentEventName: string;
	afterEventName: string;
	afterParentEventName: string;
	childSelector: string;
}

export class ContentHeaderComponent extends Component {
	private _contentHeaderChildren: ContentHeaderItem[] = [];

	constructor(element: HTMLElement, options: IContentHeaderOptions) {
		super(element, options);

		const children = this._getSortableChildren();

		if (children.length > 0) {
			// Iterate over the valid children elements and create a new instance for each of them
			children.map((childElement: HTMLElement) => this._contentHeaderChildren.push(new ContentHeaderItem(childElement, options)));

			// Attach a click event on the parent element to listen for any click coming from the children.
			this.element.addEventListener('_contentHeaderItemSelected', this._contentItemSelectedHandler);
		}
	}

	public destroy() {
		this.element.removeEventListener('_contentHeaderItemSelected', this._contentItemSelectedHandler);

		if (this._contentHeaderChildren.length > 0) {
			this._contentHeaderChildren.forEach((headerItemChild) => headerItemChild.destroy());
		}
	}


	// Getters and Setters
	// =============================================================================
	get activeChildComponent() {
		return this._contentHeaderChildren.find((childItem) => childItem.active === true);
	}

	get childrenComponents() {
		return this._contentHeaderChildren;
	}

	get options(): IContentHeaderOptions {
		return super.getOptions() as IContentHeaderOptions;
	}


	// Private Methods
	// =============================================================================
	private _getSortableChildren = () => {
		// Since children can be defined as sortable or static, filter out ones that don't have a sortable attribute.
		return toArray(this.element.querySelectorAll(this.options.childSelector))
			.filter((childElement: HTMLElement) => childElement.dataset.sortable !== undefined);
	}

	private _findChildItemInstance = (childElement: HTMLElement) => {
		return this._contentHeaderChildren.find((child) => childElement.id === child.element.id);
	}

	private _contentItemSelectedHandler = (event: CustomEvent) => {
		const selectedContentHeaderInstance = event.detail.component as ContentHeaderItem;
		const eventObject = {
			component: this,
			childComponent: selectedContentHeaderInstance
		};

		// EVENT: Before event
		this._dispatchEvent(this.options.beforeParentEventName, eventObject);
		this._dispatchEvent(this.options.beforeEventName, eventObject, selectedContentHeaderInstance.element);

		// Map over the children setting all active states to false except the chosen one.
		this._contentHeaderChildren
			.map((childElement: ContentHeaderItem) => {
				if (selectedContentHeaderInstance.element.id === childElement.element.id) {
					// Tell the target element to update the attributes
					childElement.active = true;
					return;
				}

				childElement.active = false;
				return;
			});

		// EVENT: After event
		this._dispatchEvent(this.options.afterParentEventName, eventObject);
		this._dispatchEvent(this.options.afterEventName, eventObject, selectedContentHeaderInstance.element);
	}

	private static _defaultComponentOptions = {
		idPrefix: 'iris_content_header',
		beforeEventName: 'iris.sort.before',
		beforeParentEventName: 'iris.contentHeader.sort.before',
		afterEventName: 'iris.sort.after',
		afterParentEventName: 'iris.contentHeader.sort.after',
		childSelector: '.iris-content-header__item',
	};

	private static _factoryOptions: IComponentFactoryOptions = {
		defaultQuerySelector: '.iris-content-header',
		componentName: 'ContentHeaderComponent'
	};

	public static factory = new GenericComponentFactory<ContentHeaderComponent, IContentHeaderOptions>(ContentHeaderComponent, ContentHeaderComponent._factoryOptions, ContentHeaderComponent._defaultComponentOptions);
}
