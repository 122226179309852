import * as dom from './dom';
import { logger } from './logger';
import * as utility from './utility';

export class FocusManager {
	private _element: HTMLElement;

	constructor(element: HTMLElement, heirarchyOfSelectors?: string[][]) {
		this._element = element;

		if (heirarchyOfSelectors) {
			this.heirarchyOfFocusSelectors = heirarchyOfSelectors;
		}
	}

	public heirarchyOfFocusSelectors: string[][] = [[]];
	public previousFocusElement: HTMLElement = null;

	public setFocus(focusElement?: HTMLElement) {
		this.previousFocusElement = (document.activeElement || document.body) as HTMLElement;
		focusElement = focusElement || this.focusElement;

		try {
			focusElement.focus();
		} catch (error) {
			logger({
				component: 'FocusHandler',
				message: 'Unable to focus on targeted element',
				type: 'warn'
			});
		}
	}

	public returnFocus() {
		if (!this.previousFocusElement) {
			return;
		}

		// If this is a standard focusable element (e.g. <button>) its tabindex will be 0
		if (this.previousFocusElement.tabIndex !== 0) {

			// Set the tabindex to allow the element to be focused programatically.
			this.previousFocusElement.setAttribute('tabindex', '-1');
		}

		this.previousFocusElement.focus();

		// Clean out the _previousFocusElement since we have now returned focus to it.
		this.previousFocusElement = null;
	}

	public get element() {
		return this._element;
	}

	public get focusElement() {
		// Loop through the list of selector arrays and return if an element is found
		for (let index = 0; index < this.heirarchyOfFocusSelectors.length; index++) {
			const querySelector = this.heirarchyOfFocusSelectors[index].join(', ');
			const possibleFocusElements = utility.toArray(this.element.querySelectorAll(querySelector));
			const focusElement = possibleFocusElements.find((el) => dom.isVisible(el));

			if (focusElement) {
				return focusElement as HTMLElement;
			}
		}

		return this.element;
	}

}
