import * as dom from '../dom';
import { logger } from '../logger';
import * as utility from '../utility';
import { CompassStepChangeAction } from './compass';

export class CompassStep {

	private _element: HTMLElement;

	constructor(element: HTMLElement) {
		this._element = element;
		this.element.setAttribute('tabindex', '-1');

		if (!this.element.id) {
			logger({
				component: 'Compass',
				message: 'All Compass Steps should define an id',
				type: 'warn'
			});
		}
	}

	public get active(): boolean {
		return (this.element.getAttribute('data-compass-active') || '').toLowerCase() === 'true';
	}

	public set active(bool: boolean) {
		if (bool === this.active) {
			return;
		}

		this.element.setAttribute('data-compass-active', bool.toString());
		this.element.setAttribute('aria-hidden', (!bool).toString());

		CompassStep.setupTransition(this.element, bool);
	}

	public get element(): HTMLElement {
		return this._element;
	}

	public get id(): string {
		return this.element.id;
	}

	public set id(id: string) {
		this.element.id = id;
	}

	public get nextStepId(): string {
		return this.element.getAttribute('data-compass-next-step');
	}

	public set nextStepId(stepId: string) {
		stepId ?
			this.element.setAttribute('data-compass-next-step', stepId) :
			this.element.removeAttribute('data-compass-next-step');
	}

	public get previousStepId(): string {
		return this.element.getAttribute('data-compass-previous-step');
	}

	public set previousStepId(stepId: string) {
		stepId ?
			this.element.setAttribute('data-compass-previous-step', stepId) :
			this.element.removeAttribute('data-compass-previous-step');
	}

	public get title(): string {
		return this.element.getAttribute('data-compass-step-title');
	}

	public set title(title) {
		this.element.setAttribute('data-compass-step-title', title);
	}

	public get isFirst(): boolean {
		return this.element.hasAttribute('data-compass-first-step');
	}

	private static _getTransitionClassNames(isActive: boolean): { [key: string]: string } {
		return {
			transitionStartClass: 'iris-compass--transition-active-' + isActive.toString(),
			transitioningClass: 'iris-compass--transitioning-active-' + isActive.toString(),
			transitionEndClass: 'iris-compass--active-' + isActive.toString()
		};
	}

	public static setupTransition(element: HTMLElement, isActive: boolean) {
		const { transitionStartClass, transitioningClass, transitionEndClass } = CompassStep._getTransitionClassNames(isActive);
		const allClassNames = [transitionStartClass, transitioningClass, transitionEndClass, ...Object.values(CompassStep._getTransitionClassNames(!isActive)) ];
		const done = () => {
			element.classList.remove(...allClassNames);
			element.classList.add(transitionEndClass);
			dom.dispatchEvent(element, 'iris.compass.transition.end');
		};

		dom.dispatchEvent(element, 'iris.compass.transition.start');

		element.classList.remove(...Object.values(CompassStep._getTransitionClassNames(!isActive)));
		element.classList.add(transitionStartClass);
		const hasTransition = parseFloat(getComputedStyle(element).transitionDuration) !== 0;

		if (!hasTransition) {
			done();
			return;
		}

		dom.handleOnce(element, 'transitionend', () => {
			done();
		});

		element.classList.add(transitioningClass);
	}
}
