import * as dom from '../dom';
import * as utility from '../utility';

export class ListItem {
	private _element: HTMLElement;

	constructor(element: HTMLElement) {
		this._element = element;

		let dupes: HTMLElement[] = [];

		if (this.element.dataset.value === null || typeof this.element.dataset.value === 'undefined') {
			this.element.dataset.value = this.element.innerText.trim();
		}

		if (!this.element.id) { // Stops unecessary mutation.
			this.element.id = utility.generateUniqueId('iris_list_item');
		} else {
			dupes = utility.toArray(document.querySelectorAll(`#${this.element.id}`));
		}

		// The duplicate ID scenario can happen in situations such as
		// using knockout without a template. Iris fires adding a generated id
		// then knockout copies it in a foreach. The dropdown then gets confused.
		if (dupes.length > 1) {
			const isGeneratedId = this.element.id.startsWith('iris_list_item_');
			const isFirst = dupes.indexOf(this.element) === 0;

			if (!isGeneratedId) {
				// We didn't do it! blow up!
				throw new Error(`The element ID ${this.element.id} is not unique.`);
			}

			if (!isFirst) {
				// We are going to try and recover since we *probably* generated the ID.
				this.element.id = utility.generateUniqueId('iris_list_item');
			}
		}

		this.readOnly = this.readOnly; // normalizes readOnly state.
		this.disabled = this.disabled; // normalizes disabled state.

		if (!this.element.getAttribute('aria-selected')) {
			this.element.setAttribute('aria-selected', 'false');
		}

		element.addEventListener('click', this._clickHandler);
	}

	get element(): HTMLElement {
		return this._element;
	}

	get value(): string {
		return this.element.dataset.value;
	}

	set value(value: string) {
		this.element.dataset.value = value;
	}

	get id(): string {
		return this.element.id;
	}

	set id(id: string) {
		this.element.id = id;
	}

	get selected(): boolean {
		return this.element.hasAttribute('selected') || (this.element.getAttribute('aria-selected') || '').toLowerCase() === 'true';
	}

	set selected(bool: boolean) {
		if (bool) {
			this.element.setAttribute('selected', 'selected');
		} else {
			this.element.removeAttribute('selected');
		}

		const role = this.element.getAttribute('role');
		if (role === 'listitem') {
			this.element.removeAttribute('aria-selected');
		} else {
			this.element.setAttribute('aria-selected', bool.toString());
			this._updateCheckbox();
		}
	}

	get active(): boolean {
		return (this.element.getAttribute('data-active') || '').toLowerCase() === 'true';
	}

	set active(bool: boolean) {
		this.element.dataset.active = bool.toString();
	}

	get disabled(): boolean {
		return this.element.hasAttribute('disabled');
	}

	set disabled(bool: boolean) {
		this.element.setAttribute('aria-disabled', bool.toString());

		if (bool) {
			this.element.setAttribute('disabled', 'disabled');
		} else {
			this.element.removeAttribute('disabled');
		}
	}

	get readOnly(): boolean {
		return this.element.getAttribute('role') === 'listitem';
	}

	set readOnly(bool: boolean) {
		const previousRole = this.element.getAttribute('role');
		const role = bool ? 'listitem' : 'option';

		if (previousRole !== role) {
			this.element.setAttribute('role', role);
			this.selected = this.selected; // normalizes selected state if changed by attributes.
			this._updateCheckbox();
		}
	}

	get text(): string {
		return this.element.getAttribute('aria-label') || this.element.innerText.trim();
	}

	public destroy() {
		if (this._element) {
			this._element.removeEventListener('click', this._clickHandler);
		}
	}

	private _clickHandler = (event: Event) => {
		if (this.readOnly || this.disabled) {
			return;
		}
		dom.dispatchEvent(this.element, '_listitemselected', { component: this });

		// conditionalize below if not navigable
		if (this.element.parentElement.className.includes('navigable')) {
			return;
		}
		event.cancelBubble = true;

		event.preventDefault();
		event.stopPropagation();
	}

	private _updateCheckbox() {
		const checkboxElements = utility.toArray(this.element.querySelectorAll('.iris-checkbox'));
		checkboxElements.forEach((checkboxElement: HTMLElement) => {
			const inputElement = checkboxElement.querySelector('.iris-checkbox__input') as HTMLInputElement;

			if (this.readOnly) { // give them back their checkbox!
				if (checkboxElement.getAttribute('aria-hidden') === 'true') {
					checkboxElement.setAttribute('aria-hidden', 'false');
				}
				inputElement.readOnly = false;
				inputElement.tabIndex = 0;
				return;
			}

			if (checkboxElement.getAttribute('aria-hidden') !== 'true') {
				checkboxElement.setAttribute('aria-hidden', 'true');
			}
			inputElement.readOnly = true;
			inputElement.tabIndex = -1;
			inputElement.checked = this.selected;
		});
	}

	public static createElement(value: string, textOrHtmlElement: string | HTMLElement): HTMLLIElement {
		const listOption = document.createElement('LI') as HTMLLIElement;
		listOption.classList.add('iris-list-item');
		listOption.setAttribute('data-value', value);

		const listContent = document.createElement('DIV') as HTMLElement;
		listContent.classList.add('iris-list-item__content');

		if (utility.isString(textOrHtmlElement)) {
			listContent.innerText = textOrHtmlElement as string;
		} else {
			listContent.appendChild(textOrHtmlElement as HTMLElement);
		}

		listOption.append(listContent);

		return listOption;
	}
}
