import { dispatchEvent } from './dom';
import { splitAttributeToList } from './utility';

// Interface
// =============================================================================
export interface ITransitionOptions {
	afterTransitionEventName?: string;
	childWithTransitionSelector?: string;
	addClassString?: string;
	removeClassString?: string;
	type: 'immediate' | 'timeout' | 'transition';
	wait?: number;
}


// Runner helper functions
// =============================================================================
const defaultTransitionOptions: ITransitionOptions = {
	afterTransitionEventName: null,
	childWithTransitionSelector: null,
	addClassString: null,
	type: 'transition',
	removeClassString: null,
	wait: null,
};

const mergeOptionsWithDefaults = (options: any[]): ITransitionOptions[] => {
	// Merge the options object with the set of default ones
	return options.map((item: ITransitionOptions | string): ITransitionOptions => {
		if (typeof item === 'string') {
			// We assume this is an array of classnames
			return Object.assign({}, defaultTransitionOptions, { className: item });
		}

		return Object.assign({}, defaultTransitionOptions, item);
	});
};

const toggleClasses = (element: HTMLElement, addClassString: string, removeClassString: string) => {
	if (addClassString) {
		const addClassNameArray = splitAttributeToList(addClassString);

		addClassNameArray.forEach((classString) => {
			element.classList.toggle(classString, true);
		});
	}

	if (removeClassString) {
		const removeClassNameArray = splitAttributeToList(removeClassString);

		removeClassNameArray.forEach((classString) => {
			element.classList.toggle(classString, false);
		});
	}
};

const countUniqueTransitionValues = (arrayToCount: string[]): number => {
	const filteredArray = arrayToCount.map((item) => item.trim())
		.filter((item) => {
			const prefixRegex = /(-webkit-|-moz-|-o-|-ms-)/;

			// With autoprefixer, there are additional prefixes that get added in.
			// We need to remove these prefixes to get an accurate count of transition properties.
			return !prefixRegex.test(item);
		})
		// Filter again to only get unique values. In some cases we get returned a value of 'transform, transform'.
		.filter((item, index, origArray) => origArray.indexOf(item) === index);

	return filteredArray.length;
};

const transitionFinalizingHandler = (element: HTMLElement, transitionStep: ITransitionOptions, transitionSteps: ITransitionOptions[]) => {
	// Emit the after transition event name for this step
	if (transitionStep.afterTransitionEventName) {
		dispatchEvent(element, transitionStep.afterTransitionEventName);
	}

	// If there are more than 0 classes left, call the function recursively.
	if (transitionSteps.length) {
		transitionHandler(element, transitionSteps);
	}
};

const transitionHandler = (element: HTMLElement, transitionSteps: ITransitionOptions[]) => {
	const transitionStep = transitionSteps.shift();

	// If immediate is set, go ahead and toggle the classes and call the finalize handler.
	if (transitionStep.type === 'immediate') {
		toggleClasses(element, transitionStep.addClassString, transitionStep.removeClassString);
		transitionFinalizingHandler(element, transitionStep, transitionSteps);

		return;
	}

	// If there is a wait option set, just use a setTimeout instead of a transition event
	if (transitionStep.type === 'timeout' && transitionStep.wait !== null) {
		setTimeout(() => {
			toggleClasses(element, transitionStep.addClassString, transitionStep.removeClassString);
			transitionFinalizingHandler(element, transitionStep, transitionSteps);
		}, transitionStep.wait);

		return;
	}

	// If there are multiple properties being transitioned, we need to wait until all
	// have finished before moving to the next step.
	const transitionElement: HTMLElement = element.querySelector(transitionStep.childWithTransitionSelector) || element;
	const transitionProperty: string = window.getComputedStyle(transitionElement, null)['transition-property' as any] || '';
	const numberOfTransitionProperties = countUniqueTransitionValues(transitionProperty.split(',')) - 1;
	let transitionCounter = 0;

	const transitionHandlerFunction = (event: Event) => {
		// If there are more properties than the current counter, bail out
		if (transitionCounter < numberOfTransitionProperties) {
			transitionCounter++;
			return;
		}

		// If not childWithTransitionSelector element and not the element who's event listener
		// is currently being invoked, bail out
		if (event.target !== transitionElement && event.target !== event.currentTarget) {
			return;
		}

		// Clean up the event listener to prevent duplicate events
		element.removeEventListener('transitionend', transitionHandlerFunction);
		transitionFinalizingHandler(element, transitionStep, transitionSteps);
	};

	element.addEventListener('transitionend', transitionHandlerFunction);
	toggleClasses(element, transitionStep.addClassString, transitionStep.removeClassString);
};


// Main runner
// =============================================================================
export function transitionRunner(element: HTMLElement, options: ITransitionOptions[] | string[]) {
	const transitionSteps = mergeOptionsWithDefaults(options);

	transitionHandler(element, transitionSteps);
}
