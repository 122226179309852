import { Component, IComponentOptions } from './component';
import { ComponentManager, IComponentCache } from './component-manager';

import { logger } from './logger';
import { generateUniqueId, toArray } from './utility';

export interface IComponentFactoryOptions {
	defaultQuerySelector: string;
	componentName: string;
}

export abstract class ComponentFactory<T extends Component, K extends IComponentOptions> {
	private _createFn: (element: HTMLElement, options?: K) => T;
	private _options: IComponentFactoryOptions;

	protected get defaultNodes(): NodeList {
		return document.querySelectorAll(this._options.defaultQuerySelector);
	}

	constructor(createComponentFn: (element: HTMLElement, options?: K) => T, options: IComponentFactoryOptions) {
		this._createFn = createComponentFn;
		this._options = options;
	}


	// Component property queries
	// =============================================================================
	public get componentName(): string {
		return this._options.componentName;
	}

	public get querySelector(): string {
		return this._options.defaultQuerySelector;
	}

	public get create(): (element: HTMLElement, options?: K) => T {
		return this._createFn;
	}


	// Component manager queries
	// =============================================================================
	public get components(): IComponentCache {
		return ComponentManager.defaultManager.getComponentsByType(this._options.componentName);
	}


	// Common component methods
	// =============================================================================
	public init(nodes?: Node | NodeList, options?: K): T[] {
		const nodeList = nodes || this.defaultNodes;

		return toArray(nodeList).map((element: HTMLElement) => {
				const irisId = element.dataset.irisId;
				let component = null;

				if (!irisId) {
						element.setAttribute('data-iris-id', generateUniqueId('iris', Object.keys(this.components)));
				}

				// If the element has an IrisId, ask the manager if there is an instance already for this specific component
				component = ComponentManager.defaultManager.findInstanceForElement(irisId, this.componentName);

				// Only call the create function if a component doesn't already exist.
				// Also, once the component has been created, register it with the manager.
				if (!component) {
						component = this.create(element, options);
						ComponentManager.defaultManager.add(component);
				}

				return component;
		});
	}

	public destroy(nodes?: Node | NodeList): T[] {
		const nodeList = nodes || this.defaultNodes;

		return ComponentManager.defaultManager.destroyComponent(nodeList, this.componentName);
	}

	public refresh(nodes?: Node | NodeList, options?: K): T[] {
		const nodeList = nodes || this.defaultNodes;

		this.destroy(nodeList);
		const component = this.init(nodeList, options);
		this.cleanup();

		return component;
	}

	// Cleanup any zombies out there who aren't part of the active DOM.
	public cleanup() {
		return ComponentManager.defaultManager.disposeZombieInstances(this.componentName);
	}

	public componentForElement(element: HTMLElement): T {
		if (!element) {
			console.error('No element was passed in, returning null');
			return null;
		}

		return ComponentManager.defaultManager.findInstanceForElement(element.dataset.irisId, this.componentName);
	}
}

export class GenericComponentFactory<T extends Component, K extends IComponentOptions> extends ComponentFactory<T, K> {
	constructor(
		componentConstructor: new(element: HTMLElement, options?: K) => T,
		factoryOptions: IComponentFactoryOptions, defaultComponentOptions?: IComponentOptions
	) {
		super((element: HTMLElement, options?: K) => {
			return new componentConstructor(element, Object.assign({ componentName: factoryOptions.componentName }, defaultComponentOptions, options));
		}, factoryOptions);
	}
}
