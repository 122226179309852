import * as dateUtility from '../date-util';
import * as dom from '../dom';
import { createKeypressEventListener, IKeyPressHandlers } from '../keypress-handler';
import * as utility from '../utility';

export interface ICalendarDayEvent {
	eventDate: Date | string;
	eventLabel: string | null;
	avatarIconClass?: string;
	avatarIconQuantity?: number;
	customData?: any;
}

interface IParentCalendarOptions {
	endDateLimit: Date | string | null;
	startDateLimit: Date | string | null;
}

export class CalendarDayComponent {
	public element: HTMLDivElement;

	private _date: Date;
	private _dayButtonElement: HTMLButtonElement;
	private _dayButtonWrapperElement: HTMLDivElement;
	private _today: Date;
	private _event: ICalendarDayEvent;
	private _startDateLimit: Date | string | null;
	private _endDateLimit: Date | string | null;
	private _rippleElement: HTMLElement;
	private _selected: boolean;

	constructor(date: Date, today: Date = new Date(), event: ICalendarDayEvent, calendarOptions: IParentCalendarOptions) {
		// Setting private variables
		this._date = date;
		this._endDateLimit = calendarOptions.endDateLimit;
		this._event = event;
		this._selected = false;
		this._startDateLimit = calendarOptions.startDateLimit;
		this._today = today;

		// Create starting element
		this.element = document.createElement('div');
		this.element.classList.add('iris-day');
		this.element.setAttribute('role', 'gridcell');

		// Create the inner elements of the day and append them to the main element.
		this._generateAndPopulateDayElement();
		this.element.append(this._dayButtonElement);

		// Add event listeners
		this._addEventListeners();
	}

	public destroy() {
		this._removeEventListeners();
	}


	// Element creator methods
	// =============================================================================
	private _generateAndPopulateDayElement() {
		// Generate the wrapper element
		// NOTE: This is needed because of a FireFox bug and how it handles flexbox inside of a button element
		// TODO: Remove when this bug is fixed: https://bugzilla.mozilla.org/show_bug.cgi?id=1397768
		this._dayButtonElement = document.createElement('button');
		this._dayButtonElement.classList.add('iris-day__button');
		this._dayButtonElement.setAttribute('aria-label', '');

		this._dayButtonWrapperElement = document.createElement('div');
		this._dayButtonWrapperElement.classList.add('iris-day__button-wrapper');

		this._dayButtonElement.append(this._dayButtonWrapperElement);

		// Generate inner elements
		this._generateNumberElement();
		this._generateButtonAriaLabel();

		if (this._event.eventLabel) {
			this._generateLabelElement(this._event);
		}

		if (this._event.avatarIconQuantity && this._event.avatarIconQuantity > 0) {
			this._generateIcons(this._event);
		}

		this._setCurrentOrPastDay();
		this._addAccessibilityAttributes();
	}

	private _generateNumberElement() {
		const numberElement = document.createElement('span');
		numberElement.classList.add('iris-day__number');
		numberElement.setAttribute('aria-hidden', 'true');
		numberElement.innerText = this._date.getUTCDate().toString();

		this._dayButtonWrapperElement.append(numberElement);
	}

	private _generateButtonAriaLabel() {
		const ariaLabel = this._dayButtonElement.getAttribute('aria-label');
		const descriptionText = `${dateUtility.findMonthName(this._date.getUTCMonth())} ${utility.getOrdinal(this._date.getUTCDate())}, ${this._date.getUTCFullYear()}`;

		this._dayButtonElement.setAttribute('aria-label', ariaLabel.concat(descriptionText));
	}

	private _generateLabelElement(event: ICalendarDayEvent) {
		// Creating event day label element
		const eventDayLabelElement = document.createElement('span');
		const ariaLabel = this._dayButtonElement.getAttribute('aria-label');

		eventDayLabelElement.innerText = this._event.eventLabel;
		eventDayLabelElement.classList.add('iris-day__label', 'font-style--italic');

		this._dayButtonWrapperElement.append(eventDayLabelElement);
		this._dayButtonElement.setAttribute('aria-label', ariaLabel.concat(`, ${this._event.eventLabel}`));

		if (event.avatarIconQuantity && event.avatarIconQuantity) {
			eventDayLabelElement.classList.add('truncate');
		}
	}

	private _generateIcons(event: ICalendarDayEvent) {
		// Creating the main avatar wrapper
		const avatarMainElement = document.createElement('div');
		const ariaLabel = this._dayButtonElement.getAttribute('aria-label');
		let ariaLabelText = '';

		avatarMainElement.classList.add('iris-avatar', 'iris-day__icon', 'iris-day__avatar');
		avatarMainElement.setAttribute('data-size', 'xsmall');

		// Creating avatar icon
		const avatarIconElement = document.createElement('span');
		avatarIconElement.classList.add('iris-avatar__icon', this._event.avatarIconClass);

		// Creating the inner avatar wrapper
		const innerContainerElement = document.createElement('span');
		innerContainerElement.classList.add('iris-avatar__inner');
		innerContainerElement.appendChild(avatarIconElement);

		ariaLabelText = ', one event';

		if (event.avatarIconQuantity === 2) {
			avatarMainElement.setAttribute('data-stack', '2');
			ariaLabelText = ', two events';
		} else if (event.avatarIconQuantity >= 3) {
			avatarMainElement.setAttribute('data-stack', '3');
			ariaLabelText = ', three or more events';
		}

		this._dayButtonElement.setAttribute('aria-label', ariaLabel.concat(ariaLabelText));

		// Append the created elements
		avatarMainElement.appendChild(innerContainerElement);
		this._dayButtonWrapperElement.append(avatarMainElement);
	}

	private _setCurrentOrPastDay() {
		// If this day is today, set a class of iris-day--today
		const isToday = dateUtility.isSameDay(this._date, this._today);
		this.element.classList.toggle('iris-day--today', isToday);

		// If this day is in the past, set a class of iris-day--past
		const beforeToday = !isToday && this._date < this._today;
		this.element.classList.toggle('iris-day--past', beforeToday);

		// If this day is before the valid start date, set an attribute of disabled
		// -1 as a return from the compare function indicates the date is before the start date limit
		if (this._startDateLimit && dateUtility.compare(this._date, this._startDateLimit) === -1) {
			this.element.classList.add('disabled-day');
			this._dayButtonElement.disabled = true;
		}

		// If this day is after the valid end date, set an attribute of disabled
		// 1 as a return from the compare function indicates the date is after the end date limit
		if (this._endDateLimit && dateUtility.compare(this._date, this._endDateLimit) === 1) {
			this.element.classList.add('disabled-day');
			this._dayButtonElement.disabled = true;
		}
	}

	private _addAccessibilityAttributes() {
		this.element.setAttribute('data-day-of-week', this._date.getDay().toString());
		this.element.setAttribute('aria-colindex', (this._date.getDay() + 1).toString());
		this.element.setAttribute('data-calendar-date', this._date.toISOString().split('T', 1)[0]);
	}

	private _rippleAnimation(clientX: number, clientY: number) {
		if (!this._rippleElement && !this.element.classList.contains('disabled-day')) {
			this._rippleElement = document.createElement('div');
			this._rippleElement.classList.add('ripple');
			this.element.append(this._rippleElement);

			const clientWidthAndHeight = Math.max(this.element.clientWidth, this.element.clientHeight);
			const calendarDayRect = this.element.getBoundingClientRect();

			clientX = clientX ? clientX : calendarDayRect.left + Math.floor(Math.random() * Math.floor(clientWidthAndHeight));
			clientY = clientY ? clientY : calendarDayRect.top + Math.floor(Math.random() * Math.floor(clientWidthAndHeight));

			// if calendar day have an icon the ripple animation must come from the avatar icon
			if (this._event.avatarIconQuantity && this._event.avatarIconQuantity > 0) {
				const avatarContainer = this.element.querySelector('.iris-day__icon') as HTMLElement;
				const avatarInner = avatarContainer.querySelector('.iris-avatar__inner') as HTMLElement;
				const avatarInnerRect = avatarInner.getBoundingClientRect();

				this._rippleElement.style.animation = 'none';
				this._rippleElement.style.width = `${avatarInnerRect.width}px`;
				this._rippleElement.style.height = `${avatarInnerRect.height}px`;
				this._rippleElement.style.top = `${avatarContainer.offsetTop}px`;
				this._rippleElement.style.left = `${avatarContainer.offsetLeft}px`;
				this._rippleElement.style.transform = 'scale(0)';
				this._rippleElement.style.transition = '0.3s';
				this._rippleElement.style.transform = 'scale(18)';
			} else {
				this._rippleElement.style.width = this._rippleElement.style.height = clientWidthAndHeight + 'px';
				this._rippleElement.style.left = clientX - calendarDayRect.left - clientWidthAndHeight / 2 + 'px';
				this._rippleElement.style.top = clientY - calendarDayRect.top - clientWidthAndHeight / 2 + 'px';
			}
		}
	}

	private _removeRipple() {
		this._rippleElement.remove();
		this._rippleElement = null;
		this.element.classList.remove('selected');
	}


	// Public getters and setters
	// =============================================================================
	get date(): Date {
		return this._date;
	}

	get event(): ICalendarDayEvent {
		return this._event;
	}

	get isToday(): boolean {
		if (dateUtility.isSameDay(this._date, this._today)) {
			return true;
		}
		return false;
	}

	set selected(bool: boolean) {
		// If the selected state is the same, don't dispatch a new event.
		if (this._selected === bool) {
			return;
		}

		this._selected = bool;

		if (bool) {
			dom.dispatchEvent(this.element, '_calendarDaySelected', {
				component: this,
				event: this._event,
			});
		}

		if (!bool && this._rippleElement) {
			this._removeRipple();
		}
	}

	get selected(): boolean {
		return this._selected;
	}


	// Private helper methods
	// =============================================================================
	private _addEventListeners() {
		this.element.addEventListener('click', this._clickHandler);
		this._dayButtonElement.addEventListener('keydown', this._keypressHandler);
	}

	private _removeEventListeners() {
		this.element.removeEventListener('click', this._clickHandler);
		this._dayButtonElement.addEventListener('keydown', this._keypressHandler);
		this._dayButtonElement.addEventListener('keydown', this._keypressHandler);
	}

	private _clickHandler = (event: MouseEvent) => {
		if (!this.element.classList.contains('disabled-day')) {
			this.element.classList.add('selected');

			this.selected = true;
			this._rippleAnimation(event.clientX, event.clientY);

			event.cancelBubble = true;
		}
	}

	private _keypressHandler = (event: Event) => { this._createKeypressEventListener()(event); };

	private _createKeypressEventListener(): EventListener {

		const ripple = () => {
			this.element.classList.add('selected');
			this.selected = true;
			this._rippleAnimation(null, null);
			event.cancelBubble = true;
		};

		const navigate = (direction: string) => {
			dom.dispatchEvent(this.element, '_calendarKeyboardNavigate', { component: this, direction });
		};

		return createKeypressEventListener({
			up: () => navigate('up'),
			down: () => navigate('down'),
			left: () => navigate('left'),
			right: () => navigate('right'),
			spacebar: () => ripple(),
			enter: () => ripple(),
		} as IKeyPressHandlers) as EventListener;
	}
}
